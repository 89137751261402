import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { sendData } from "../services/sendData";
import localStorageKeys from "../localStorageKeys";
import { HoverableRow, HoverableBorderTd } from "../styles/hoverableRow";

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const ButtonInRow = styled.button`
  margin-right: 1em;
`;

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const URLS = {
  getSystems: "/api/sysmap/systems",
  getSites: "/api/sysmap/sites",
  manipulateSystem: "/api/sysmap/system",
};

class SysmapSystems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      systems: [],
      sites: [],
      editedSystem: null,
    };
  }

  loadData = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState(
      {
        isLoading: true,
        isError: false,
        systems: [],
        sites: [],
        editedSystem: null,
      },
      async () => {
        try {
          const token = localStorage.getItem(localStorageKeys.userToken);
          const systemsRes = await sendData(
            null,
            URLS.getSystems,
            token,
            "GET"
          );
          const sitesRes = await sendData(null, URLS.getSites, token, "GET");

          if (!systemsRes.ok || !sitesRes.ok) {
            throw new Error("bad responses");
          }

          const systems = systemsRes.data.map(
            ({
              sys_id,
              site_id,
              internal_id,
              description,
              ac_capacity,
              dc_capacity,
            }) => {
              return {
                sysId: sys_id,
                siteId: site_id || "",
                internalId: internal_id,
                description: description,
                acCap: "" + ac_capacity,
                dcCap: "" + dc_capacity,
              };
            }
          );

          const sites = [
            { siteId: "", internalId: "no site", siteName: "no site" },
            ...sitesRes.data.map(({ internal_id, name, site_id }) => {
              return {
                siteId: site_id,
                internalId: internal_id,
                siteName: name,
              };
            }),
          ];

          if (!this._isMounted) {
            return;
          }
          this.setState({
            systems,
            sites,
            isLoading: false,
            isError: false,
          });
        } catch (error) {
          console.error(error);
          this.setState({ isLoading: false, isError: true });
        }
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddSystem = () => {
    this.setState({
      editedSystem: {
        isNew: true,
        sysId: uuidv4(),
        siteId: "",
        internalId: "",
        description: "",
        acCap: "",
        dcCap: "",
      },
    });
  };

  handleEditProp = (propName) => (ev) => {
    const newVal = ev.target.value;
    this.setState((state) => {
      const editedSystem = { ...state.editedSystem };
      editedSystem[propName] = newVal;
      return {
        editedSystem,
      };
    });
  };

  handleEditSystem =
    (sysId, siteId, internalId, description, acCap, dcCap) => () => {
      this.setState({
        editedSystem: {
          isNew: false,
          sysId,
          siteId,
          internalId,
          description,
          acCap,
          dcCap,
        },
      });
    };

  handleSaveChanges = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const { sysId, siteId, internalId, description, acCap, dcCap, isNew } =
          this.state.editedSystem;
        const site_id = siteId || null;
        const ac_capacity = +acCap;
        const dc_capacity = +dcCap;
        if (!Number.isFinite(ac_capacity) || !Number.isFinite(dc_capacity)) {
          window.alert("system's capacities must be numeric");
          return this.setState({ isLoading: false });
        }

        const token = localStorage.getItem(localStorageKeys.userToken);
        const editSysRes = await (isNew
          ? sendData(
              {
                values: {
                  sys_id: sysId,
                  site_id,
                  internal_id: internalId,
                  description,
                  ac_capacity,
                  dc_capacity,
                },
              },
              URLS.manipulateSystem,
              token,
              "POST"
            )
          : sendData(
              {
                where: { sys_id: sysId },
                values: {
                  site_id,
                  internal_id: internalId,
                  description,
                  ac_capacity,
                  dc_capacity,
                },
              },
              URLS.manipulateSystem,
              token,
              "PUT"
            ));

        if (!editSysRes.ok) {
          window.alert("Error, try again later");
          return this.setState({ isLoading: false });
        }
      } catch (error) {
        console.error(error);
      }

      this.loadData();
    });
  };

  handleCancelEdit = () => {
    this.setState({ editedSystem: null });
  };

  handleDeleteSystem = () => {
    const userRes = window.confirm(
      "you are about to delete a system, are you sure?"
    );

    if (!userRes) {
      return;
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const token = localStorage.getItem(localStorageKeys.userToken);
        await sendData(
          { where: { sys_id: this.state.editedSystem.sysId } },
          URLS.manipulateSystem,
          token,
          "DELETE"
        );
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    });
  };

  render() {
    const { isLoading, isError, systems, sites, editedSystem } = this.state;

    if (isError) {
      return <Section>Error loading data</Section>;
    }
    if (isLoading) {
      return <Section>Loading data...</Section>;
    }

    if (editedSystem) {
      const { isNew, sysId, siteId, internalId, description, acCap, dcCap } =
        editedSystem;
      return (
        <Section>
          <h3>
            {isNew ? "add" : "edit"} system - '{internalId}'
          </h3>
          <Section>uuid: {sysId}</Section>
          <Section>
            internal-id:{" "}
            <input
              type="text"
              value={internalId}
              onChange={this.handleEditProp("internalId")}
            />
          </Section>
          <Section>
            description:{" "}
            <input
              type="text"
              value={description}
              onChange={this.handleEditProp("description")}
            />
          </Section>
          <Section>
            ac-capacity:{" "}
            <input
              type="text"
              value={acCap}
              onChange={this.handleEditProp("acCap")}
            />
          </Section>
          <Section>
            dc-capacity:{" "}
            <input
              type="text"
              value={dcCap}
              onChange={this.handleEditProp("dcCap")}
            />
          </Section>
          <Section>
            site:{" "}
            <select value={siteId} onChange={this.handleEditProp("siteId")}>
              {sites.map((site, idx) => {
                return (
                  <option key={idx} value={site.siteId}>
                    {site.siteName} ({site.internalId}, {site.siteId || "none"})
                  </option>
                );
              })}
            </select>
          </Section>
          <Section>
            <button disabled={isNew} onClick={this.handleDeleteSystem}>
              delete
            </button>
          </Section>
          <Section>
            <ButtonInRow onClick={this.handleSaveChanges}>save</ButtonInRow>
            <ButtonInRow onClick={this.handleCancelEdit}>cancel</ButtonInRow>
          </Section>
        </Section>
      );
    }

    return (
      <Section>
        <h3>Sysmap systems:</h3>
        <Section>
          <ButtonInRow onClick={this.handleAddSystem}>new system</ButtonInRow>
          <ButtonInRow onClick={this.loadData}>refresh data</ButtonInRow>
        </Section>
        <table>
          <thead>
            <tr>
              {/* sysId internalId description acCap dcCap siteId */}
              <TableHeaderCell>uuid</TableHeaderCell>
              <TableHeaderCell>internal-id</TableHeaderCell>
              <TableHeaderCell>description</TableHeaderCell>
              <TableHeaderCell>ac-capacity</TableHeaderCell>
              <TableHeaderCell>dc-capacity</TableHeaderCell>
              <TableHeaderCell>site</TableHeaderCell>
              <TableHeaderCell>edit</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {systems.map(
              (
                { sysId, internalId, description, acCap, dcCap, siteId },
                idx
              ) => {
                const site = sites.find((s) => s.siteId === siteId);
                return (
                  <HoverableRow key={idx}>
                    <HoverableBorderTd>{sysId}</HoverableBorderTd>
                    <HoverableBorderTd>{internalId}</HoverableBorderTd>
                    <HoverableBorderTd>{description}</HoverableBorderTd>
                    <HoverableBorderTd>{acCap}</HoverableBorderTd>
                    <HoverableBorderTd>{dcCap}</HoverableBorderTd>
                    <HoverableBorderTd>
                      {site.siteName} ({site.internalId}, {siteId || "none"})
                    </HoverableBorderTd>
                    <HoverableBorderTd>
                      <button
                        onClick={this.handleEditSystem(
                          sysId,
                          siteId,
                          internalId,
                          description,
                          acCap,
                          dcCap
                        )}
                      >
                        edit
                      </button>
                    </HoverableBorderTd>
                  </HoverableRow>
                );
              }
            )}
          </tbody>
        </table>
      </Section>
    );
  }
}

export default SysmapSystems;
