import styled from "styled-components";

export const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";

export const HoverableRow = styled.tr`
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

export const HoverableTd = styled.td`
  color: unset;
  background: unset;
`;

export const HoverableBorderTd = styled.td`
  color: unset;
  background: unset;
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;
