import * as Sma from "./Sma";
import * as Smav2 from "./Smav2";
import * as SmaWc from "./SmaWc";
import * as Mock from "./Mock";
import * as Fronius from "./Fronius";
import * as Neteco from "./Neteco";
import * as Sungrow from "./Sungrow";
// in this style a timestamp relates to the interval until the next measure timestamp
import * as BackendFusionStyle from "./BackendFusionStyle";
// in this style a timestamp relates to the interval that ends with that timestamp
import * as BackendSmaStyle from "./BackendSmaStyle";

// not used anymore
// import * as SolarEdge from "./SolarEdge";
// import * as Weblog from "./Weblog";
// import * as BluelogXM from "./BlulogXM";
// not production ready
// import * as BlulogXSeries from "./BlulogXSeries";

export default {
  sma: Sma,
  smav2: Smav2,
  solaredge: BackendFusionStyle,
  mock: Mock,
  fronius: Fronius,
  smaWc: SmaWc,
  weblog: BackendSmaStyle,
  neteco: Neteco,
  sungrow: Sungrow,
  blulogXM: BackendSmaStyle,
  fusion: BackendFusionStyle,
  goodwe: BackendFusionStyle,
  smartPVMS: BackendFusionStyle,
  smaApi: BackendSmaStyle,
  nineRen: BackendFusionStyle,
  // blulogXSeries: BackendSmaStyle,
};
