import * as React from "react";
import styled from "styled-components";
import { HoverableRow, HoverableBorderTd } from "../../styles/hoverableRow";

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const DbItemsTable = ({ items, headers }) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map(({ show }, idx) => {
            return <TableHeaderCell key={idx}>{show}</TableHeaderCell>;
          })}
        </tr>
      </thead>
      <tbody>
        {Object.entries(items).map(([entryKey, item]) => {
          const values = headers.map(({ key, mutator }) => {
            const val = mutator ? mutator(item[key]) : item[key];
            return val;
          });
          return (
            <HoverableRow key={entryKey}>
              {values.map((val, idx) => (
                <HoverableBorderTd key={idx}>{val}</HoverableBorderTd>
              ))}
            </HoverableRow>
          );
        })}
      </tbody>
    </table>
  );
};

export default DbItemsTable;
