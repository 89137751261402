import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { sendData } from "../services/sendData";
import localStorageKeys from "../localStorageKeys";
import { HoverableRow, HoverableBorderTd } from "../styles/hoverableRow";

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const ButtonInRow = styled.button`
  margin-right: 1em;
`;

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const URLS = {
  getOrgs: "/api/sysmap/orgs",
  getSites: "/api/sysmap/sites",
  getAssociations: "/api/sysmap/org-site-associations",
  manipulateOrg: "/api/sysmap/org",
  manipulateOrgSite: "/api/sysmap/org-site-association",
};

class SysmapOrgs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      orgs: [],
      sites: [],
      editedOrg: null,
    };
  }

  loadData = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState(
      {
        isLoading: true,
        isError: false,
        orgs: [],
        sites: [],
        editedOrg: null,
      },
      async () => {
        try {
          const token = localStorage.getItem(localStorageKeys.userToken);
          const orgsRes = await sendData(null, URLS.getOrgs, token, "GET");
          const sitesRes = await sendData(null, URLS.getSites, token, "GET");
          const associationsRes = await sendData(
            null,
            URLS.getAssociations,
            token,
            "GET"
          );

          if (!orgsRes.ok || !sitesRes.ok || !associationsRes.ok) {
            throw new Error("bad responses");
          }

          const orgs = orgsRes.data.map(({ org_id, org_name }) => {
            return {
              orgId: org_id,
              orgName: org_name,
              siteIds: [],
            };
          });

          const sites = sitesRes.data.map(({ internal_id, name, site_id }) => {
            return {
              siteId: site_id,
              internalId: internal_id,
              siteName: name,
            };
          });

          associationsRes.data.forEach(({ org_id, site_id }) => {
            orgs.find((e) => e.orgId === org_id).siteIds.push(site_id);
          });

          if (!this._isMounted) {
            return;
          }
          this.setState({
            orgs,
            sites,
            isLoading: false,
            isError: false,
          });
        } catch (error) {
          console.error(error);
          this.setState({ isLoading: false, isError: true });
        }
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddOrg = () => {
    this.setState({
      editedOrg: {
        isNew: true,
        orgId: uuidv4(),
        orgName: "",
        siteIds: [],
      },
    });
  };

  handleEditOrgName = (ev) => {
    const newVal = ev.target.value;
    this.setState((state) => {
      const editedOrg = { ...state.editedOrg };
      editedOrg.orgName = newVal;
      return {
        editedOrg,
      };
    });
  };

  handleAssociateSite = (siteId) => () => {
    this.setState((state) => {
      const editedOrg = { ...state.editedOrg };
      if (editedOrg.siteIds.includes(siteId)) {
        editedOrg.siteIds = editedOrg.siteIds.filter((e) => e !== siteId);
      } else {
        editedOrg.siteIds.push(siteId);
      }
      return { editedOrg };
    });
  };

  handleEditOrg = (orgId, orgName, siteIds) => () => {
    this.setState({
      editedOrg: {
        isNew: false,
        orgId,
        orgName,
        siteIds: [...siteIds],
      },
    });
  };

  handleSaveChanges = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const {
          orgId: org_id,
          orgName: org_name,
          siteIds: editedSiteIds,
          isNew,
        } = this.state.editedOrg;

        const originalSiteIds =
          this.state.orgs.find((o) => o.orgId === org_id)?.siteIds || [];
        const delOrgSites = originalSiteIds.filter(
          (site) => !editedSiteIds.includes(site)
        );
        const addOrgSites = editedSiteIds.filter(
          (site) => !originalSiteIds.includes(site)
        );

        const token = localStorage.getItem(localStorageKeys.userToken);
        const editOrgRes = await (isNew
          ? sendData(
              { values: { org_id, org_name } },
              URLS.manipulateOrg,
              token,
              "POST"
            )
          : sendData(
              {
                where: { org_id },
                values: { org_name },
              },
              URLS.manipulateOrg,
              token,
              "PUT"
            ));

        if (!editOrgRes.ok) {
          window.alert("Error, try again later");
          return this.setState({ isLoading: false });
        }

        await Promise.all(
          delOrgSites.map((site_id) =>
            sendData(
              { where: { org_id, site_id } },
              URLS.manipulateOrgSite,
              token,
              "DELETE"
            )
          )
        );

        await Promise.all(
          addOrgSites.map((site_id) =>
            sendData(
              { values: { org_id, site_id } },
              URLS.manipulateOrgSite,
              token,
              "POST"
            )
          )
        );
      } catch (error) {
        console.error(error);
      }

      this.loadData();
    });
  };

  handleCancelEdit = () => {
    this.setState({ editedOrg: null });
  };

  handleDeleteOrg = () => {
    const userRes = window.confirm(
      "you are about to delete an organization, are you sure?"
    );

    if (!userRes) {
      return;
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const token = localStorage.getItem(localStorageKeys.userToken);
        await sendData(
          { where: { org_id: this.state.editedOrg.orgId } },
          URLS.manipulateOrg,
          token,
          "DELETE"
        );
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    });
  };

  render() {
    const { isLoading, isError, orgs, sites, editedOrg } = this.state;

    if (isError) {
      return <Section>Error loading data</Section>;
    }
    if (isLoading) {
      return <Section>Loading data...</Section>;
    }

    if (editedOrg) {
      const { isNew, orgId, orgName, siteIds } = editedOrg;
      return (
        <Section>
          <h3>
            {isNew ? "add" : "edit"} organization - '{orgName}'
          </h3>
          <Section>uuid: {orgId}</Section>
          <Section>
            name:{" "}
            <input
              type="text"
              value={orgName}
              onChange={this.handleEditOrgName}
            />
          </Section>
          <Section>
            <button disabled={isNew} onClick={this.handleDeleteOrg}>
              delete
            </button>
          </Section>
          <Section>
            <ButtonInRow onClick={this.handleSaveChanges}>save</ButtonInRow>
            <ButtonInRow onClick={this.handleCancelEdit}>cancel</ButtonInRow>
          </Section>
          <Section>
            <div>sites:</div>
            {sites.map(({ siteId, internalId, siteName }, idx) => {
              return (
                <div key={idx}>
                  <input
                    type="checkbox"
                    checked={siteIds.includes(siteId)}
                    onChange={this.handleAssociateSite(siteId)}
                  />
                  {internalId}: {siteName} ({siteId})
                </div>
              );
            })}
          </Section>
        </Section>
      );
    }

    return (
      <Section>
        <h3>Sysmap organizations:</h3>
        <Section>
          <ButtonInRow onClick={this.handleAddOrg}>new org</ButtonInRow>
          <ButtonInRow onClick={this.loadData}>refresh data</ButtonInRow>
        </Section>
        <table>
          <thead>
            <tr>
              <TableHeaderCell>uuid</TableHeaderCell>
              <TableHeaderCell>name</TableHeaderCell>
              <TableHeaderCell>sites</TableHeaderCell>
              <TableHeaderCell>edit</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {orgs.map(({ orgId, orgName, siteIds }, idx) => {
              return (
                <HoverableRow key={idx}>
                  <HoverableBorderTd>{orgId}</HoverableBorderTd>
                  <HoverableBorderTd>{orgName}</HoverableBorderTd>
                  <HoverableBorderTd>
                    <select>
                      {siteIds.map((siteId, idx) => {
                        const site = sites.find((s) => s.siteId === siteId);
                        return (
                          <option key={idx}>
                            {site.internalId} ({site.siteName})
                          </option>
                        );
                      })}
                    </select>
                  </HoverableBorderTd>
                  <HoverableBorderTd>
                    <button
                      onClick={this.handleEditOrg(orgId, orgName, siteIds)}
                    >
                      edit
                    </button>
                  </HoverableBorderTd>
                </HoverableRow>
              );
            })}
          </tbody>
        </table>
      </Section>
    );
  }
}

export default SysmapOrgs;
