import moment from "moment";
// import { SNSClient, PublishCommand } from "@aws-sdk/client-sns";
// if you want to reinject this code, the current verions of this package is 3.3.0

import { sendData } from "../services/sendData";
import LocalStorageKeys from "../localStorageKeys";

// const CLIENT_CONFIG = {
//   region: process.env.REACT_APP_AWS_REGION,
//   credentials: {
//     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   }
// }

// returns an array of possible sms types
export const getMessageTypes = () => {
  return ["Transactional", "Promotional"];
};

// // client to send sns through
// const snsClient = new SNSClient(CLIENT_CONFIG);
// /**
//  *
//  * @param message message to send
//  * @param phoneNumber - phone number in E.164
//  * @param senderId string with the sender Id to present to recipients
//  * @param {"Promotional" | "Transactional"} messageType
//  * @param senderPhone string that contains the sender phone number
//  * @returns {Promise<PromiseResult<unknown, unknown>>}
//  */
// export function sendSMSOld(message, phoneNumber, senderId, messageType) {
//   const params = {
//       Message: message,
//       PhoneNumber: phoneNumber,
//       MessageAttributes: {
//           'AWS.SNS.SMS.SenderID': {
//               DataType: "String",
//               StringValue: senderId
//           },
//           'AWS.SNS.SMS.SMSType': {
//             DataType: "String",
//             StringValue: messageType
//           }
//       },
//   };

//   return snsClient.send(new PublishCommand(params));
// }

export function sendSMS(
  message,
  phoneNumbers,
  senderId,
  messageType,
  language,
  systemId = "",
  datetime = null,
  status = "open",
  emailAddresses = [],
  subject = "",
  emailFooter = null
) {
  if (typeof message === "string" && message.length === 0) {
    return Promise.reject(new Error("message can't be empty"));
  }
  if (datetime === null) {
    datetime = moment();
  }
  const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
  const phones = (Array.isArray(phoneNumbers) ? phoneNumbers : [phoneNumbers])
    .map((p) => p.trim())
    .filter((p) => p);
  const emails = (
    Array.isArray(emailAddresses) ? emailAddresses : [emailAddresses]
  )
    .map((e) => e.trim())
    .filter((e) => e);
  const url = "/api/messages";
  const body = {
    systemId: systemId,
    dateTime: datetime.format(DATETIME_FORMAT),
    message: message,
    phones: phones,
    emails: emails,
    status: status,
    topic: senderId,
    subject: subject,
    language: language,
    messageType: messageType, // for now it is not used on the server side, later should be amended
  };
  if (emailFooter && typeof emailFooter === typeof "string") {
    body.emailFooter = emailFooter;
  }
  const token = localStorage.getItem(LocalStorageKeys.userToken);
  return sendData(body, url, token, "post").then(
    (res) => res.data.awsResponses
  );
}
