import React from "react";
import withAuthorization from "../components/Session/withAuthorization";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";

// import MigrateAnnual from "./MigrateAnnual";
import MigrateMonthly from "./MigrateMonthly";
// import MirgrateMessages from "./MigrateMessages";

// routes
const MONTHLY_INSIGHTS = "monthly_insights";
// const ANNUAL_INSIGHTS = "annual_insights";
// const MESSAGES_MLOG = "messages_mlog";

const activeStyle = {
  color: "teal",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LIST_BG_COLOR = "#2f2f2f";
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: ${LIST_BG_COLOR};
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

class MigrationsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match, isLoading, loadError, systemsData } = this.props;

    return (
      <Wrapper>
        <LinksList>
          <LinksListItem key="monthly">
            <NavLink
              to={`${match.url}/${MONTHLY_INSIGHTS}`}
              activeStyle={activeStyle}
            >
              Monthly
            </NavLink>
          </LinksListItem>
          {/* <LinksListItem key="sys">
            <NavLink
              to={`${match.url}/${ANNUAL_INSIGHTS}`}
              activeStyle={activeStyle}
            >
              Annual
            </NavLink>
          </LinksListItem>
          <LinksListItem key="messages">
            <NavLink
              to={`${match.url}/${MESSAGES_MLOG}`}
              activeStyle={activeStyle}
            >
              Messages
            </NavLink>
          </LinksListItem> */}
        </LinksList>
        <Content>
          <Switch>
            <Route
              path={`${match.path}/${MONTHLY_INSIGHTS}`}
              render={() => (
                <MigrateMonthly {...{ isLoading, loadError, systemsData }} />
              )}
            />
            {/* <Route
              path={`${match.path}/${ANNUAL_INSIGHTS}`}
              render={() => (
                <MigrateAnnual {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Route
              path={`${match.path}/${MESSAGES_MLOG}`}
              render={() => (
                <MirgrateMessages {...{ isLoading, loadError, systemsData }} />
              )}
            /> */}
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/${MONTHLY_INSIGHTS}`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(MigrationsPage));
