import React from "react";
import withAuthorization from "../components/Session/withAuthorization";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";
import DailyInsights from "../containers/DailyInsights";
import styled from "styled-components";
import Shadow from "../containers/Shadow";

const activeStyle = {
  color: "teal",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LIST_BG_COLOR = "#2f2f2f";
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: ${LIST_BG_COLOR};
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { match, isLoading, loadError, systemsData } = this.props;

    return (
      <Wrapper>
        <LinksList>
          <LinksListItem key="maximizers">
            <NavLink to={`${match.url}/maximizers`} activeStyle={activeStyle}>
              Maximizers
            </NavLink>
          </LinksListItem>
          <LinksListItem key="shadow">
            <NavLink to={`${match.url}/shadow`} activeStyle={activeStyle}>
              Shadow
            </NavLink>
          </LinksListItem>
        </LinksList>
        <Content>
          <Switch>
            <Route
              path={`${match.path}/maximizers`}
              render={() => (
                <DailyInsights {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Route
              path={`${match.path}/shadow`}
              render={() => (
                <Shadow {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/maximizers`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(Insights));
